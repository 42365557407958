import { makeStyles, shorthands } from '@fluentui/react-components';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { ChatWindow } from '../chat/ChatWindow';
import { ChatList } from '../chat/chat-list/ChatList';
import DrawerMenu from '../DrawerMenu';
import { Breakpoints } from '../../styles';
import { ImagesList } from '../chat/images-list/ImagesList';
import FeaturesModal from '../FeaturesModal';
import ThirdPopover from '../ThirdPopover';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('auto'),
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        overflowY: 'auto',
    },
    view: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'start',
        height: '100dvh',
        width: '100%',
    },
    chatList: {
        ...Breakpoints.small({
            display: 'none',
        }),
        width: '100%',
        maxWidth: '242px',
        ...shorthands.borderRight('1px', 'solid', '#E0D6C8'),
    },
    drawerMenu: {
        display: 'none',
        ...Breakpoints.small({
            display: 'block',
        }),
    },
});

export const ChatView = () => {
    const classes = useClasses();
    const { selectedTab } = useAppSelector((state: RootState) => state.app);

    return (
        <div className={classes.container}>
            <FeaturesModal />
            <ThirdPopover />
            <div className={classes.drawerMenu}>
                <DrawerMenu />
            </div>
            {selectedTab === 'chat' ? (
                <div className={classes.chatList}>
                    <ChatList />
                </div>
            ) : (
                ''
            )}
            {selectedTab === 'images' || selectedTab === 'gallery' || selectedTab === 'favorites' ? (
                <div className={classes.chatList}>
                    <ImagesList />
                </div>
            ) : (
                ''
            )}
            <div className={classes.view}>
                <ChatWindow />
            </div>
        </div>
    );
};
