import { Button, Textarea, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import React, { useState } from 'react';
import { COPY } from '../../assets/strings';
import { AlertType } from '../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert, removeAlert } from '../../redux/features/app/appSlice';
import { editConversationInput } from '../../redux/features/conversations/conversationsSlice';
import { Alerts } from '../shared/Alerts';
import { updateUserIsTyping } from './../../redux/features/conversations/conversationsSlice';

import pdf from '../../assets/pdf/code_of_use.pdf';
import { Breakpoints } from '../../styles';
import { useAbortController } from '../../AbortControllerProvider';
import { ImagesStatus } from './ImagesStatus';
import { updateMessageSuccess } from '../../redux/features/images/imagesSlice';

const infoMessage = "Wait for MaIA's response before sending a new message";

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.margin(0, '72px'),
        ...Breakpoints.small({
            ...shorthands.margin(0, '15px'),
        }),
    },
    typingIndicator: {
        minHeight: '28px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'end',
    },
    content: {
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        width: '100%',
        '& .fui-Textarea:after': {
            display: 'none',
        },
        '& .fui-Textarea': {
            ...shorthands.border('0 !important'),
            paddingBottom: '0',
            ...shorthands.outline('0'),
        },
        '& .fui-Textarea:hover': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
        '& .fui-Textarea:focus': {
            ...shorthands.border('0', 'solid', 'transparent'),
        },
    },
    input: {
        width: '100%',
    },
    textarea: {
        maxHeight: '230px',
        ...shorthands.padding('12px', '170px', '12px', '12px'),
        ...shorthands.borderRadius('4px', '4px', '4px', '4px'),
        ...shorthands.border('1px', 'solid', '#1A1D20'),
        '&:focus': {
            ...shorthands.borderColor('#9CB0EC'),
            ...shorthands.outline('0'),
        },
        minHeight: '48px',
        height: '48px',
    },
    controls: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#54616D',
        fontFamily: 'LVMH Sans',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        ...Breakpoints.small({
            fontSize: '12px',
        }),
    },
    essentials: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 'auto', // align to right
        position: 'absolute',
        right: '0',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: '100',
    },
    btn: {
        backgroundColor: '#576DDD',
        ...shorthands.border('0'),
        maxWidth: '48px',
        width: '48px',
        maxHeight: '32px',
        height: '32px',
        marginRight: '7px',
        '&:hover': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
            '&:active': {
                backgroundColor: '#576DDD',
                opacity: '0.7',
            },
        },
        '&:active': {
            backgroundColor: '#576DDD',
            opacity: '0.7',
        },
    },
    btnAttach: {
        color: '#576DDD',
        fontWeight: 300,
        ...shorthands.padding('0'),
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    menuList: {
        alignItems: 'center',
    },
    menuItem: {
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:hover:active': {
            backgroundColor: 'transparent',
            color: '#576ddd',
        },
        '&:nth-of-type(2)': {
            ...shorthands.borderBottom('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderTop('1px', 'solid', '#E0E5F0'),
            ...shorthands.borderRadius('0'),
        },
    },
});

const StopIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
            <g>
                <path fill="white" d="M0 0h24v24H0z"></path>
                <path d="M6 7v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1z" fill="white"></path>
            </g>
        </svg>
    );
};

interface ChatInputProps {
    onSubmit: (options: any) => Promise<void>;
}

export const ImagesInput: React.FC<ChatInputProps> = ({ onSubmit }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo, alerts } = useAppSelector((state: RootState) => state.app);
    const { isMessageSuccess } = useAppSelector((state: RootState) => state.images.conversation);
    const { currentController } = useAbortController();

    const [value, setValue] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

    React.useEffect(() => {
        // Function to focus on the text area
        const focusOnTextArea = () => {
            textAreaRef.current?.focus();
        };

        // If the screen matches the media query, focus on the text area
        const mediaQuery = window.matchMedia('(min-width: 745px)');
        const handleMediaQueryChange = () => {
            if (mediaQuery.matches) {
                focusOnTextArea();
            }
        };

        // Initial focus based on media query
        handleMediaQueryChange();

        // Listen for changes in the media query
        mediaQuery.addListener(handleMediaQueryChange);

        // Clean up event listener on component unmount
        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, [selectedId]);

    React.useEffect(() => {
        if (isMessageSuccess) {
            const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
            dispatch(removeAlert(alertIndex));
        }
    }, [isMessageSuccess]);

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState.disabled ? COPY.CHAT_DELETED_MESSAGE() : chatState.input);
    }, [conversations, selectedId]);

    const handleSubmit = (value: { prompt: string }) => {
        dispatch(updateMessageSuccess(false));
        setIsSubmitted(true);
        if (value.prompt.trim() === '') {
            return; // only submit if value is not empty
        }
        // const hubConnection = getOrCreateHubConnection(store);
        // // handleReceiveMessageUpdate(hubConnection, store);
        // hubConnection
        //     .invoke('AddClientToGroupAsync', selectedId)
        //     .catch((err) => store.dispatch(addAlert({ message: String(err), type: AlertType.Error })));
        setValue('');
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        // dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Calling the kernel' }));
        onSubmit({ ...value }).catch((error: unknown) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    const handleStop = () => {
        // const currentMessage = conversations[selectedId].messages.find((message) => message.content === '');
        // if (currentMessage?.id) {
        //     dispatch(
        //         updateMessageProperty({
        //             chatId: selectedId,
        //             messageIdOrIndex: currentMessage.date,
        //             property: 'content',
        //             value: '<Your query have been cancelled>',
        //             frontLoad: true,
        //         }),
        //     );
        // }
        currentController?.abort();
        const alertIndex = alerts.findIndex((obj) => obj.message === infoMessage);
        dispatch(removeAlert(alertIndex));
        setIsSubmitted(false);
        // const hubConnection = getOrCreateHubConnection(store);
        // hubConnection
        //     .invoke('StopConnection', selectedId)
        //     .catch((err) => dispatch(addAlert({ message: String(err), type: AlertType.Error })));
        // hubConnection.off('ReceiveMessageUpdate');
        // hubConnection.on('ReceiveMessageUpdate', () => {});
    };

    const SubmitIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.16602 0.814453L15.0243 7.99997L2.16602 15.1855V0.814453ZM3.16602 2.51883V7.49997H6.66602V8.49997H3.16602V13.4811L12.9744 7.99997L3.16602 2.51883Z"
                fill="white"
            />
        </svg>
    );

    return (
        <div className={classes.root}>
            <div className={classes.typingIndicator}>
                <ImagesStatus />
            </div>
            <Alerts />
            <div className={classes.content}>
                <Textarea
                    title="Chat input"
                    aria-label="Chat input field. Click enter to submit input."
                    ref={textAreaRef}
                    id="chat-input"
                    resize="vertical"
                    as="textarea"
                    disabled={conversations[selectedId].disabled}
                    textarea={{
                        className: classes.textarea,
                    }}
                    className={classes.input}
                    value={value}
                    onFocus={() => {
                        // update the locally stored value to the current value
                        const chatInput = document.getElementById('chat-input');
                        if (chatInput) {
                            setValue((chatInput as HTMLTextAreaElement).value);
                        }
                        // User is considered typing if the input is in focus
                        if (activeUserInfo) {
                            dispatch(
                                updateUserIsTyping({ userId: activeUserInfo.id, chatId: selectedId, isTyping: true }),
                            );
                        }
                    }}
                    onChange={(_event, data) => {
                        setValue(data.value);
                        dispatch(editConversationInput({ id: selectedId, newInput: data.value }));
                    }}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                            event.preventDefault();
                            if (value.trim() === '') {
                                return;
                            }
                            if (value.length > 0 && (!isSubmitted || isMessageSuccess)) {
                                const optionsValue = {
                                    prompt: value,
                                };
                                handleSubmit(optionsValue);
                            } else {
                                const shouldAddAlert = !alerts.some((obj) =>
                                    obj.message ? obj.message.includes(infoMessage) : false,
                                );

                                if (shouldAddAlert) {
                                    dispatch(
                                        addAlert({
                                            message: infoMessage,
                                            type: AlertType.Info,
                                        }),
                                    );
                                }
                            }
                        }
                    }}
                    onBlur={() => {
                        // User is considered not typing if the input is not  in focus
                        if (activeUserInfo) {
                            dispatch(
                                updateUserIsTyping({ userId: activeUserInfo.id, chatId: selectedId, isTyping: false }),
                            );
                        }
                    }}
                />
                <div className={classes.essentials}>
                    {!isSubmitted || isMessageSuccess ? (
                        <Button
                            className={classes.btn}
                            title="Submit"
                            aria-label="Submit message"
                            appearance="transparent"
                            icon={<SubmitIcon />}
                            onClick={() => {
                                if (value.length > 0) {
                                    const optionsValue = {
                                        prompt: value,
                                    };
                                    handleSubmit(optionsValue);
                                }
                            }}
                            size="large"
                            disabled={conversations[selectedId].disabled || !value.length}
                        />
                    ) : (
                        <Button
                            className={classes.btn}
                            title="Stop"
                            aria-label="Stop generating"
                            appearance="transparent"
                            icon={<StopIcon />}
                            onClick={() => {
                                handleStop();
                            }}
                            disabled={conversations[selectedId].disabled}
                        />
                    )}
                </div>
            </div>
            <div className={classes.controls}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                    By using LVMH MAIA, you agree to respect LVMH{' '}
                    <a style={{ color: '#000' }} href={pdf} target="_blank" rel="noreferrer">
                        code of use
                    </a>{' '}
                    and allow chat history collection for service improvements.
                </div>{' '}
            </div>
        </div>
    );
};
