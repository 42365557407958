import { Image, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { ChatListSection } from './ChatListSection';

import logo from '../../../assets/images/LogoNew.svg';
import { Breakpoints } from '../../../styles';
import { SimplifiedNewBotMenu } from './bot-menu/SimplifiedNewBotMenu';
import { useChat } from '../../../libs/hooks';
import { setCreatedNew } from '../../../redux/features/conversations/conversationsSlice';
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { setIsFeaturesModalOpen } from '../../../redux/features/app/appSlice';
import { useSelector } from 'react-redux';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexShrink: 0,
        width: '100%',
        backgroundColor: '#fff',
        flexDirection: 'column',
        height: '100%',
        ...shorthands.overflow('hidden'),
        ...Breakpoints.small({
            maxWidth: '285px',
        }),
    },
    list: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
        alignItems: 'stretch',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: tokens.spacingVerticalM,
        marginLeft: tokens.spacingHorizontalXL,
        alignItems: 'center',
        height: '60px',
    },
    title: {
        flexGrow: 1,
        fontSize: tokens.fontSizeBase500,
    },
    input: {
        flexGrow: 1,
        ...shorthands.padding(tokens.spacingHorizontalNone),
        ...shorthands.border(tokens.borderRadiusNone),
        backgroundColor: tokens.colorSubtleBackground,
        fontSize: tokens.fontSizeBase500,
    },
    logo: {
        maxWidth: '95px',
        marginLeft: '16px',
        marginRight: 'auto',
        marginTop: '16px',
        marginBottom: '40px',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

const ExpandMoreIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.99996 10.7071L3.64641 6.35355L4.35352 5.64645L7.99996 9.29289L11.6464 5.64645L12.3535 6.35355L7.99996 10.7071Z"
            fill="#1A1D20"
        />
    </svg>
);

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    () => ({
        border: `0`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }),
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />
))(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 700,
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(180deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: 0,
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
    padding: 0,
}));

interface ConversationsView {
    filteredConversations?: Conversations;
    latestConversations?: Conversations;
    olderConversations?: Conversations;
}

export const ChatList: FC = () => {
    const classes = useClasses();
    const { conversations, selectedId, createdNew } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const [disabled, setDisabled] = useState(false);
    const [conversationsView, setConversationsView] = useState<ConversationsView>({
        latestConversations: conversations,
    });
    const [initialRender, setInitialRender] = useState(true);
    const [expanded, setExpanded] = useState<string | false>(false);
    const dispatch = useAppDispatch();
    const { popovers } = useSelector((state: RootState) => state.popover);
    const shadowId = popovers[2] ? 'popover3' : '';

    useEffect(() => {
        if (selectedId && conversations) {
            const categorizedConversations = categorizeConversationsByDate(conversations);
            Object.keys(categorizedConversations).forEach((category) => {
                const conversationsForCategory = categorizedConversations[category];
                if (selectedId in conversationsForCategory) {
                    setExpanded(category);
                }
            });
        }
    }, [conversations, selectedId]);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            return;
        }

        if (createdNew) {
            dispatch(setCreatedNew(false));
            setDisabled(false);
            return;
        }

        if (!selectedId) {
            return;
        }

        // eslint-disable-next-line
        const ifConversationMessagesLoaded = selectedId && conversations && conversations[selectedId]?.messagesLoaded;

        if (ifConversationMessagesLoaded) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const hasMessages = selectedId && conversations && conversations[selectedId]?.messages.length > 0;

        if (!hasMessages) {
            setDisabled(true);
            void Promise.all([
                chat
                    .loadChatById(selectedId, conversations)
                    .then(() => {
                        setDisabled(false);
                    })
                    .catch(() => {
                        // Handle error
                    }),
            ]);
        }
    }, [selectedId, conversations, createdNew, initialRender]);

    const categorizeConversationsByDate = (conversations: Conversations): Record<string, Conversations> => {
        const todayConversations: Conversations = {};
        const yesterdayConversations: Conversations = {};
        const lastTwoWeeksConversations: Conversations = {};
        const olderConversations: Conversations = {};

        Object.keys(conversations).forEach((id) => {
            const conversation = conversations[id];
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const lastActivityDate = new Date(conversation?.last_activity_date ?? 0);

            if (conversation.last_activity_date) {
                if (isToday(lastActivityDate)) {
                    todayConversations[id] = conversation;
                } else if (isYesterday(lastActivityDate)) {
                    yesterdayConversations[id] = conversation;
                } else if (isWithinLastTwoWeeks(lastActivityDate)) {
                    lastTwoWeeksConversations[id] = conversation;
                }
            } else {
                olderConversations[id] = conversation;
            }
        });

        return {
            Today: todayConversations,
            Yesterday: yesterdayConversations,
            'Last 2 weeks': lastTwoWeeksConversations,
            Older: olderConversations,
        };
    };

    useEffect(() => {
        const nonHiddenConversations: Conversations = {};
        for (const key in conversations) {
            const conversation = conversations[key];
            if (!conversation.hidden) {
                nonHiddenConversations[key] = conversation;
            }
        }

        const categorizedConversations = categorizeConversationsByDate(nonHiddenConversations);
        setConversationsView(categorizedConversations);
    }, [conversations]);

    const fileUploaderRef = useRef<HTMLInputElement>(null);

    const handleChange = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root} id={shadowId}>
            <div className={classes.logo}>
                <Image src={logo} fit="contain" />
            </div>
            <div aria-label={'chat list'} className={classes.list} id="third-anchor">
                <>
                    <SimplifiedNewBotMenu
                        disabled={disabled}
                        setDisabled={setDisabled}
                        onFileUpload={() => fileUploaderRef.current?.click()}
                    />

                    {Object.keys(conversationsView).map((category) => {
                        //@ts-expect-error ts-expect-error
                        const conversationsForCategory = conversationsView[category];
                        //eslint-disable-next-line
                        if (Object.keys(conversationsForCategory).length === 0) {
                            return null;
                        }

                        return (
                            <Accordion
                                expanded={expanded === category}
                                onChange={handleChange(category)}
                                key={category}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${category}-content`}
                                    id={`${category}-header`}
                                >
                                    <Typography
                                        sx={{ fontSize: '16px', fontWeight: '700', textTransform: 'uppercase' }}
                                    >
                                        {category}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ChatListSection
                                        setDisabled={setDisabled}
                                        disabled={disabled}
                                        conversations={conversationsForCategory}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </>
            </div>
            <div
                style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    letterSpacing: '0.24px',
                    marginTop: 'auto',
                    marginBottom: '16px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                VERSION 2.0 -{' '}
                <span
                    onClick={() => {
                        dispatch(setIsFeaturesModalOpen(true));
                    }}
                    style={{ textDecoration: 'underline', textUnderlineOffset: '7px', cursor: 'pointer' }}
                >
                    WHAT&apos;S NEW ?
                </span>
            </div>
        </div>
    );
};

const isToday = (date: Date): boolean => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

const isYesterday = (date: Date): boolean => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
    );
};

const isWithinLastTwoWeeks = (date: Date): boolean => {
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14);
    return date >= twoWeeksAgo && !isYesterday(date) && !isToday(date);
};
