import { IImagesChatMessage } from './imagesSlice';
import { IGalleryItems } from '../../../libs/services/ImagesService';

export interface IImagesMessage {
    prompt_uuid?: string;
    images_uuid?: string;
    user?: string;
    image_url?: string;
    thumbnail_url?: string;
    galery_thumbnail_url?: string;
    favorite?: boolean;
    feedback?: boolean;
    date?: string;
}

export interface ImagesState {
    conversation: {
        messages: IImagesChatMessage[];
        botResponseStatus: string;
        isMessageSuccess: boolean;
    };
    gallery: {
        items: IGalleryItems[];
        total: number | null;
        page: number | null;
        size: number | null;
        pages: number | null;
    };
}

export const initialState: ImagesState = {
    conversation: {
        messages: [],
        botResponseStatus: '',
        isMessageSuccess: false,
    },
    gallery: {
        items: [],
        total: null,
        page: null,
        size: null,
        pages: null,
    },
};
