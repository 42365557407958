import React, { useEffect, useState } from 'react';
import { makeStyles, shorthands, Spinner, tokens } from '@fluentui/react-components';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { IGalleryItems } from '../../libs/services/ImagesService';
import { useImages } from '../../libs/hooks/useImages';
import { setGallery, updateFavorite, updateFeedback } from '../../redux/features/images/imagesSlice';
import {
    Dialog,
    DialogSurface,
    Button,
    Image,
    // useRestoreFocusTarget,
} from '@fluentui/react-components';
import { setSelectedTab } from '../../redux/features/app/appSlice';

const ThumbsUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <g id="Icon/ThumbsUp" clipPath="url(#clip0_1464_27000)">
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9819 4.90953C14.0525 4.40738 14.0435 4.07956 13.9968 3.89435L8.68503 9.05911C8.10297 9.64178 7.82043 10.0888 7.82043 10.5855V17.3833C7.82043 18.1986 8.54761 19.0027 9.65378 19.0027H15.3526C15.7675 19.0027 16.0373 18.9023 16.2302 18.7695C16.4301 18.6319 16.5961 18.4256 16.7449 18.14C16.7501 18.13 16.7556 18.1201 16.7613 18.1104L19.791 12.9638C20.0196 12.5457 19.9903 11.7677 19.6315 11.008C19.2642 10.2303 18.7397 9.8486 18.3423 9.85105L18.3382 9.85107H13.5438C13.3363 9.85107 13.1411 9.75298 13.0173 9.58655C12.8935 9.42012 12.8556 9.20494 12.9152 9.00624L12.9935 8.7455C13.4255 7.30741 13.8377 5.93546 13.9819 4.90953ZM15.2816 5.09226C15.1432 6.07659 14.7915 7.31422 14.4259 8.53857H18.3364L18.3342 8.53858L18.3382 9.19482V8.53857H18.3364C19.5133 8.53254 20.3787 9.51658 20.8183 10.4475C21.265 11.3933 21.4608 12.6636 20.9343 13.6084L20.9266 13.622L17.9006 18.7622C17.6902 19.1619 17.401 19.5569 16.9744 19.8506C16.5352 20.153 15.9978 20.3152 15.3526 20.3152H9.65378C7.9008 20.3152 6.50793 18.9992 6.50793 17.3833V10.5855C6.50793 9.52713 7.1385 8.74949 7.76017 8.12782L7.76672 8.12136L13.3878 2.65589C13.5024 2.54448 13.6537 2.47872 13.8133 2.47093C14.1195 2.45599 14.4282 2.51635 14.6961 2.69833C14.9629 2.8796 15.1239 3.13716 15.2166 3.39811C15.3902 3.88667 15.3648 4.50058 15.2816 5.09226ZM2.21875 11.2563C2.21875 11.0656 2.3734 10.9109 2.56418 10.9109H4.19798C4.38876 10.9109 4.54341 11.0656 4.54341 11.2563V18.6571C4.54341 18.8479 4.38876 19.0025 4.19798 19.0025H2.56418C2.3734 19.0025 2.21875 18.8479 2.21875 18.6571V11.2563ZM2.56418 9.59842C1.64853 9.59842 0.90625 10.3407 0.90625 11.2563V18.6571C0.90625 19.5727 1.64853 20.315 2.56418 20.315H4.19798C5.11363 20.315 5.85591 19.5727 5.85591 18.6571V11.2563C5.85591 10.3407 5.11363 9.59842 4.19798 9.59842H2.56418Z"
                fill="#131F39"
            />
        </g>
        <defs>
            <clipPath id="clip0_1464_27000">
                <rect width="21" height="21" fill="white" transform="translate(0.25 0.5)" />
            </clipPath>
        </defs>
    </svg>
);

const ThumbsDownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <g id="Icon/ThumbsDown" clipPath="url(#clip0_1464_27004)">
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9819 17.0905C14.0525 17.5926 14.0435 17.9204 13.9968 18.1057L8.68503 12.9409C8.10297 12.3582 7.82043 11.9112 7.82043 11.4145V4.61671C7.82043 3.80136 8.54761 2.99731 9.65378 2.99731H15.3526C15.7675 2.99731 16.0373 3.09772 16.2302 3.23048C16.4301 3.36815 16.5961 3.57443 16.7449 3.85997C16.7501 3.86998 16.7556 3.87987 16.7613 3.8896L19.791 9.03621C20.0196 9.45426 19.9903 10.2323 19.6315 10.992C19.2642 11.7697 18.7397 12.1514 18.3423 12.1489L18.3382 12.1489H13.5438C13.3363 12.1489 13.1411 12.247 13.0173 12.4134C12.8935 12.5799 12.8556 12.7951 12.9152 12.9938L12.9935 13.2545C13.4255 14.6926 13.8377 16.0645 13.9819 17.0905ZM15.2816 16.9077C15.1432 15.9234 14.7915 14.6858 14.4259 13.4614H18.3364L18.3342 13.4614L18.3382 12.8052V13.4614H18.3364C19.5133 13.4675 20.3787 12.4834 20.8183 11.5525C21.265 10.6067 21.4608 9.33644 20.9343 8.39155L20.9267 8.37794L20.9266 8.37803L17.9006 3.23778C17.6902 2.83815 17.401 2.44311 16.9745 2.14941C16.5352 1.84697 15.9978 1.68481 15.3526 1.68481H9.65378C7.9008 1.68481 6.50793 3.00081 6.50793 4.61671V11.4145C6.50793 12.4729 7.1385 13.2505 7.76017 13.8722L7.76668 13.8787L7.76672 13.8786L13.3878 19.3441C13.5024 19.4555 13.6537 19.5213 13.8133 19.5291C14.1195 19.544 14.4282 19.4837 14.6961 19.3017C14.9629 19.1204 15.1239 18.8628 15.2166 18.6019C15.3902 18.1133 15.3648 17.4994 15.2816 16.9077ZM2.21875 10.7437C2.21875 10.9344 2.3734 11.0891 2.56418 11.0891H4.19798C4.38876 11.0891 4.54341 10.9344 4.54341 10.7437V3.34291C4.54341 3.15214 4.38876 2.99748 4.19798 2.99748H2.56418C2.3734 2.99748 2.21875 3.15214 2.21875 3.34291V10.7437ZM2.56418 12.4016C1.64853 12.4016 0.90625 11.6593 0.90625 10.7437V3.34291C0.90625 2.42726 1.64853 1.68498 2.56418 1.68498H4.19798C5.11363 1.68498 5.85591 2.42726 5.85591 3.34291V10.7437C5.85591 11.6593 5.11363 12.4016 4.19798 12.4016H2.56418Z"
                fill="#131F39"
            />
        </g>
        <defs>
            <clipPath id="clip0_1464_27004">
                <rect width="21" height="21" fill="white" transform="matrix(1 0 0 -1 0.25 21.5)" />
            </clipPath>
        </defs>
    </svg>
);

const StarIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 17 16" fill="none">
        <g id="Icon Right">
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.49965 0.833008L10.4916 5.75857L15.7917 6.13096L11.7227 9.54752L13.0064 14.7032L8.49965 11.8892L3.99294 14.7032L5.27658 9.54752L1.20764 6.13096L6.50768 5.75857L8.49965 0.833008ZM7.20064 6.71235L3.74437 6.95519L6.39781 9.18321L5.56072 12.5454L8.49965 10.7103L11.4386 12.5454L10.6015 9.18321L13.2549 6.95519L9.79865 6.71235L8.49965 3.50028L7.20064 6.71235Z"
                fill="#576DDD"
            />
        </g>
    </svg>
);

const StarActiveIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 17 16" fill="none">
        <g id="Icon/Favorite">
            <path
                id="Fill"
                d="M8.50003 3.5L9.79904 6.71207L13.2553 6.95492L10.6019 9.18293L11.439 12.5451L8.50003 10.71L5.56111 12.5451L6.3982 9.18293L3.74475 6.95492L7.20103 6.71207L8.50003 3.5Z"
                fill="#576DDD"
            />
            <path
                id="Vector"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.50001 0.833008L10.492 5.75857L15.792 6.13096L11.7231 9.54752L13.0067 14.7032L8.50001 11.8892L3.99331 14.7032L5.27695 9.54751L1.20801 6.13096L6.50805 5.75857L8.50001 0.833008ZM7.20101 6.71235L3.74473 6.95519L6.39818 9.1832L5.56109 12.5454L8.50001 10.7103L11.4389 12.5454L10.6018 9.1832L13.2553 6.95519L9.79902 6.71235L8.50001 3.50028L7.20101 6.71235Z"
                fill="#576DDD"
            />
        </g>
    </svg>
);

const ReloadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29866 6.92313H7.21991V8.11063H2.27991V3.17063H3.46741V5.96125C4.69053 3.81188 7.00616 2.375 9.64241 2.375C13.2999 2.375 16.328 5.14188 16.7199 8.70438H15.5205C15.1405 5.80688 12.6468 3.5625 9.64241 3.5625C7.29116 3.5625 5.24866 4.92813 4.29866 6.92313ZM14.7012 12.0768H11.7324V10.8893H16.6843V15.8293H15.4968V13.098C14.2618 15.2118 11.9699 16.6249 9.35741 16.6249C5.69991 16.6249 2.67178 13.858 2.27991 10.2955H3.47928C3.85928 13.193 6.35303 15.4374 9.35741 15.4374C11.7087 15.4374 13.7512 14.0718 14.7012 12.0768Z"
            fill="#030F2B"
        />
    </svg>
);

const UploadIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" viewBox="0 0 21 22" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.25 11.8143V4.4375H9.75V11.8143L7.74908 9.81342L6.68842 10.8741L9.96967 14.1553L10.5 14.6857L11.0303 14.1553L14.3116 10.8741L13.2509 9.81342L11.25 11.8143ZM5.13068 12.3125H3.9375V18.875H17.0625V12.3125H15.8693V17.6635H5.13068V12.3125Z"
            fill="#030F2B"
        />
    </svg>
);

const useStyles = makeStyles({
    root: {
        ...shorthands.padding(tokens.spacingHorizontalL),
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '1000px',
    },
    sectionTitle: {
        ...shorthands.margin(0),
        fontSize: tokens.fontSizeBase400,
        fontWeight: tokens.fontWeightSemibold,
    },
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        justifyItems: 'center',
    },
    gridItem: {
        width: '120px',
        height: '120px',
        boxShadow: tokens.shadow8,
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        cursor: 'pointer',
        ':hover': {
            transform: 'scale(1.05)',
        },
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: tokens.spacingVerticalM,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        '&:hover': {
            backgroundcolor: 'red',
        },
    },
    download: {
        position: 'absolute',
        top: '15px',
        right: '45px',
        backgroundColor: '#fff',
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px'),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('5px'),
    },
    favorite: {
        position: 'absolute',
        bottom: '15px',
        right: '45px',
        backgroundColor: '#fff',
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px'),
    },
    evaluate: {
        position: 'absolute',
        bottom: '15px',
        left: '45px',
        backgroundColor: '#fff',
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px'),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('5px'),
    },
    reusePrompt: {
        position: 'absolute',
        top: '15px',
        left: '45px',
        backgroundColor: '#fff',
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('5px'),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap('5px'),
    },
    img: {
        ...shorthands.outline('none'),
    },
    dialog: {
        backgroundColor: 'transparent',
        ...shorthands.padding('0'),
        ...shorthands.border('0'),
        boxShadow: 'none',
    },
});

interface GalleryProps {
    isFavorite?: boolean;
}

export const Gallery: React.FC<GalleryProps> = ({ isFavorite = false }) => {
    const gallery = useAppSelector((state: RootState) => state.images.gallery);
    const images = useImages();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState('false');
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [favoriteLoading, setFavoriteLoading] = useState(false);
    const [feedbackLoading, setFeedbackLoading] = useState(false);
    const classes = useStyles();

    const handleNextImage = () => {
        if (currentImageIndex < gallery.items.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
            setImage(gallery.items[currentImageIndex + 1].image_url);
        }
    };

    const handlePreviousImage = () => {
        if (currentImageIndex > 0) {
            setCurrentImageIndex(currentImageIndex - 1);
            setImage(gallery.items[currentImageIndex - 1].image_url);
        }
    };

    const handleImage = (value: string, index: number) => {
        setImage(value);
        setCurrentImageIndex(index);
        setOpen(true);
    };

    const fetchGallery = async (page: number) => {
        setLoading(true);
        try {
            const response = await images.fetchGallery(page.toString(), gallery.size?.toString() ?? '10', isFavorite);
            //eslint-disable-next-line
            dispatch(setGallery(response));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        void fetchGallery(1);
    }, []);

    const handleNextPage = () => {
        if (gallery.page && gallery.page < (gallery?.pages ?? 0)) {
            void fetchGallery(gallery.page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (gallery.page && gallery.page > 1) {
            void fetchGallery(gallery.page - 1);
        }
    };

    const groupedByDate = gallery.items.reduce(
        (acc, item, originalIndex) => {
            const dateObj = new Date(item.date);
            const date = isNaN(dateObj.getTime()) ? 'Invalid Date' : dateObj.toLocaleDateString();

            if (!acc[date]) {
                acc[date] = [];
            }

            acc[date].push({ ...item, originalIndex });

            return acc;
        },
        // eslint-disable-next-line
        {} as Record<string, Array<IGalleryItems & { originalIndex: number }>>,
    );

    const downloadFile = (url: string, name: string): void => {
        fetch(url, { mode: 'no-cors' })
            .then((response) => response.blob())
            .then((blob) => {
                const blobURL = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobURL;

                a.download = name;
                document.body.appendChild(a);
                a.click();
            })
            .catch(() => {
                console.error('error');
            });
    };

    const handleFavoriteToggle = async () => {
        const currentFavoriteStatus = gallery.items[currentImageIndex].favorite;
        try {
            // Disable the button while the request is being processed
            setFavoriteLoading(true);

            // Make the API call to update the favorite status
            await images.setImageFavorite(
                gallery.items[currentImageIndex].prompt_uuid,
                gallery.items[currentImageIndex].image_uuid,
                !currentFavoriteStatus,
            );

            // Dispatch the updateFavorite action to update the status locally
            dispatch(updateFavorite({ index: currentImageIndex, favorite: !currentFavoriteStatus }));
        } catch (error) {
            console.error('Error updating favorite status:', error);
        } finally {
            setFavoriteLoading(false);
        }
    };

    const handleFeedbackToggle = async (feedbackValue: 'positive' | 'negative' | null) => {
        try {
            // Disable buttons while processing
            setFeedbackLoading(true);

            // API call to update feedback
            await images.setImageFeedback(
                gallery.items[currentImageIndex].prompt_uuid,
                gallery.items[currentImageIndex].image_uuid,
                feedbackValue,
            );

            // Dispatch to update the feedback status locally
            dispatch(updateFeedback({ index: currentImageIndex, feedback: feedbackValue }));
        } catch (error) {
            console.error('Error updating feedback status:', error);
        } finally {
            setFeedbackLoading(false);
        }
    };

    const handleFetchPromptByUuid = async (promptUuid: string) => {
        try {
            // Disable buttons while processing
            // setFeedbackLoading(true);

            // API call to update feedback
            const response = await images.fetchPromptByUuid(promptUuid);

            dispatch(setSelectedTab('images'));
            await images.createImageAsync({ prompt: response?.prompt ?? '' });

            // Dispatch to update the feedback status locally
            // dispatch(updateFeedback({ index: currentImageIndex, feedback: feedbackValue }));
        } catch (error) {
            console.error('Error updating feedback status:', error);
        } finally {
            // setFeedbackLoading(false);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onOpenChange={(_, data) => {
                    setOpen(data.open);
                }}
            >
                <DialogSurface className={classes.dialog}>
                    <div className={classes.modal}>
                        <a
                            className={classes.download}
                            onClick={() => {
                                downloadFile(image, 'test');
                            }}
                            style={{ cursor: 'pointer', color: '#030F2B', fontSize: '18px', display: 'none' }}
                        >
                            <UploadIcon />
                            <span>Download</span>
                        </a>
                        <button
                            className={classes.favorite}
                            onClick={() => {
                                void handleFavoriteToggle();
                            }}
                            style={{
                                cursor: favoriteLoading ? 'not-allowed' : 'pointer',
                                color: 'blue',
                                textDecoration: 'underline',
                            }}
                            disabled={favoriteLoading}
                        >
                            <div className={`starIcon ${gallery.items[currentImageIndex]?.favorite ? 'selected' : ''}`}>
                                {gallery.items[currentImageIndex]?.favorite ? <StarActiveIcon /> : <StarIcon />}
                            </div>
                        </button>
                        <button
                            className={classes.evaluate}
                            style={{
                                cursor: feedbackLoading ? 'not-allowed' : 'pointer',
                                color: '#030F2B',
                                fontSize: '18px',
                            }}
                            disabled={feedbackLoading}
                        >
                            <span>Evaluate</span>
                            <div
                                onClick={() => {
                                    !feedbackLoading &&
                                        gallery.items[currentImageIndex]?.feedback === 'negative' &&
                                        void handleFeedbackToggle('positive');
                                }}
                                className={`thumbsIcon ${feedbackLoading ? 'disabled' : ''} ${gallery.items[currentImageIndex]?.feedback === 'positive' ? 'selected' : ''}`}
                                style={{
                                    cursor: feedbackLoading ? 'not-allowed' : 'pointer',
                                    color: 'blue',
                                    textDecoration: 'underline',
                                }}
                            >
                                <ThumbsUpIcon />
                            </div>
                            <div
                                onClick={() => {
                                    !feedbackLoading &&
                                        !feedbackLoading &&
                                        gallery.items[currentImageIndex]?.feedback === 'positive' &&
                                        void handleFeedbackToggle('negative');
                                }}
                                className={`thumbsIcon ${feedbackLoading ? 'disabled' : ''} ${gallery.items[currentImageIndex]?.feedback === 'negative' ? 'selected' : ''}`}
                                style={{
                                    cursor: feedbackLoading ? 'not-allowed' : 'pointer',
                                    color: 'blue',
                                    textDecoration: 'underline',
                                }}
                            >
                                <ThumbsDownIcon />
                            </div>
                        </button>
                        <a
                            className={classes.reusePrompt}
                            onClick={() => {
                                void handleFetchPromptByUuid(gallery.items[currentImageIndex]?.prompt_uuid || '');
                            }}
                            style={{ cursor: 'pointer', color: '#030F2B', fontSize: '18px' }}
                        >
                            <span>Reuse Prompt</span>
                            <ReloadIcon />
                        </a>
                        <span
                            style={{
                                padding: '10px',
                                cursor: currentImageIndex === 0 ? 'not-allowed' : 'pointer',
                                fontSize: '24px',
                                color: '#fff',
                            }}
                            onClick={handlePreviousImage}
                        >
                            &lt;
                        </span>
                        <Image
                            src={image}
                            alt="carousel image"
                            style={{ maxHeight: '90%', maxWidth: '90%', borderRadius: '16px' }}
                            shadow={true}
                        />
                        <span
                            style={{
                                padding: '10px',
                                cursor: currentImageIndex === gallery.items.length - 1 ? 'not-allowed' : 'pointer',
                                fontSize: '24px',
                                color: '#fff',
                            }}
                            onClick={handleNextImage}
                        >
                            &gt;
                        </span>
                    </div>
                </DialogSurface>
            </Dialog>
            {loading ? (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                    <Spinner label="Loading..." />
                </div>
            ) : (
                <>
                    {Object.entries(groupedByDate).map(([date, items], index) => (
                        <div key={index}>
                            <h2 className={classes.sectionTitle}>{date}</h2>
                            <div className={classes.gridContainer}>
                                {items.map((item, idx) => (
                                    <div key={idx} className={classes.gridItem}>
                                        <Image
                                            onClick={() => {
                                                handleImage(item.image_url, item.originalIndex);
                                            }}
                                            src={item.galery_thumbnail_url}
                                            alt={item.prompt_uuid}
                                            className={classes.image}
                                            shadow={true}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </>
            )}
            <div className={classes.paginationContainer}>
                <Button disabled={gallery.page === 1 || loading} onClick={handlePreviousPage}>
                    Previous
                </Button>
                <span>
                    Page {gallery.page ?? 1} of {gallery.pages ?? 1}
                </span>
                <Button disabled={gallery.page === gallery.pages || loading} onClick={handleNextPage}>
                    Next
                </Button>
            </div>
        </>
    );
};
