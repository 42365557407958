import { makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import { FC } from 'react';
import { Breakpoints, SharedStyles } from '../../../styles';
import { setSelectedTab } from '../../../redux/features/app/appSlice';
import { useAppDispatch } from '../../../redux/app/hooks';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        cursor: 'pointer',
        minHeight: '48px',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
        ...Breakpoints.small({
            width: '90%',
            ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalMNudge),
        }),
    },
    actions: {
        display: 'none',
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        fontWeight: tokens.fontWeightBold,
        color: tokens.colorNeutralBackground1,
        display: 'flex',
        alignItems: 'center',
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    popoverSurface: {
        display: 'none',
    },
    selected: {
        backgroundColor: '#576DDD',
        boxShadow: 'rgb(255, 255, 255) 4px 0px 0px 0px inset',
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
    hoverActions: {
        display: 'flex',
    },
    itemArea: {
        display: 'flex',
    },
    hovered: {
        width: '70%',
    },
});

interface IImagesListItemProps {
    isSelected: boolean;
    title: string;
    icon: () => JSX.Element;
    tabSelectedName: string;
}

export const ImagesListItem: FC<IImagesListItemProps> = ({ isSelected, title, icon, tabSelectedName }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected)}
            onClick={() => {
                dispatch(setSelectedTab(tabSelectedName));
            }}
        >
            <div className={mergeClasses(classes.itemArea, isSelected ? classes.hovered : '')}>
                <div className={classes.body}>
                    <div className={classes.header}>
                        <Text className={classes.title} title={title}>
                            <span style={{ marginRight: '5px', display: 'flex' }}>{icon()}</span>
                            {title}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    );
};
