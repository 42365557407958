import { makeStyles, shorthands } from '@fluentui/react-components';
import { Drawer } from '@fluentui/react-drawer';
import { ChatList } from './chat/chat-list/ChatList';
import { useAppDispatch, useAppSelector } from '../redux/app/hooks';
import { setIsOpenMobileMenu } from '../redux/features/app/appSlice';
import { RootState } from '../redux/app/store';

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        backgroundColor: '#fff',
    },

    drawer: {
        backgroundColor: '#030F2B',
        position: 'absolute',
        top: '80px',
        left: '0',
        height: 'calc(100% - 80px)',
        zIndex: '1001',
        ...shorthands.border('0'),
        width: '100%',
        maxWidth: '285px',
        transitionDuration: '0ms !important',
    },

    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: '1000',
    },
});

const DrawerMenu = () => {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const { isOpenMobileMenu } = useAppSelector((state: RootState) => state.app);

    const drawerProps: any = {
        type: 'inline',
        separator: true,
        open: isOpenMobileMenu,
        className: styles.drawer,
        onOpenChange: (_: any, { open }: { open: boolean }) => {
            dispatch(setIsOpenMobileMenu(open));
        },
        backdropMotion: null,
        surfaceMotion: null,
    };

    return (
        <div className={styles.root}>
            {isOpenMobileMenu && (
                <div
                    onClick={() => {
                        dispatch(setIsOpenMobileMenu(!isOpenMobileMenu));
                    }}
                    className={styles.backdrop}
                ></div>
            )}
            <Drawer {...drawerProps}>
                <ChatList />
            </Drawer>
        </div>
    );
};

export default DrawerMenu;
