import React from 'react';
import { IImagesMessage } from '../../../redux/features/images/ImagesState';
import { makeStyles, shorthands } from '@fluentui/react-components';

const useClasses = makeStyles({
    content: {
        display: 'flex',
        flexWrap: 'wrap',
        ...shorthands.gap('16px'),
    },
    imageContainer: {
        ...shorthands.flex(1, 0, 'calc(50% - 16px)'), // Two images per line with gap
        textAlign: 'center',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
});

interface ChatHistoryTextContentProps {
    message: IImagesMessage[];
}

export const ImagesHistoryContent: React.FC<ChatHistoryTextContentProps> = ({ message }) => {
    const classes = useClasses();

    return (
        <div className={classes.content}>
            {message.map((imgMessage, index) => (
                <div key={index} className={classes.imageContainer}>
                    {imgMessage.galery_thumbnail_url ? (
                        <img src={imgMessage.galery_thumbnail_url} alt={`Image`} className={classes.image} />
                    ) : (
                        'no image url'
                    )}
                </div>
            ))}
        </div>
    );
};
