import botIcon1 from './assets/bot-icons/bot-icon-1.png';
import { MODEL_NAME } from './redux/features/conversations/ConversationsState';

export const Constants = {
    app: {
        name: 'Conversation',
        updateCheckIntervalSeconds: 60 * 5,
        CONNECTION_ALERT_ID: 'connection-alert',
        importTypes: '.txt,.pdf,.docx, .xlsx, .pptx, .md,.jpg,.jpeg,.png,.tif,.tiff,.bmp,.gif',
    },
    bot: {
        profile: {
            id: 'bot',
            fullName: 'Conversation',
            emailAddress: '',
            photo: botIcon1,
        },
        fileExtension: 'skcb',
        typingIndicatorTimeoutMs: 5000,
    },
};

export const modelNames = Object.values(MODEL_NAME);

export const modelLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Google Gemini 1.5 Pro',
    [MODEL_NAME.AZURE_TURBO]: 'OpenAI GPT 4o',
};
export const modelSubLabels: { [key in MODEL_NAME]: string } = {
    [MODEL_NAME.GEMINI_PRO]: 'Powerful for querying documents',
    [MODEL_NAME.AZURE_TURBO]: 'More creative',
};

export const WelcomeMessage = `
Hello 👋, I am MaIA and I can do many **NEW** things! Among other things:

* I can answer your questions using information from web pages you give me.
* I'm always up-to-date with the latest info.
* I'm faster than ever.
* I switch on-demand from one model to another so you can choose your preferred answer.

So how can I help ?
`;
