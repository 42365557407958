import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImagesMessage, ImagesState, initialState } from './ImagesState';
import { IGalleryResponse, IImagesPromptData } from '../../../libs/services/ImagesService';

export interface IImagesChatMessage {
    user: IImagesPromptData;
    ai: IImagesMessage[];
}

export const imagesSlice = createSlice({
    name: 'images',
    initialState,
    reducers: {
        setConversationMessage: (state: ImagesState, action: PayloadAction<IImagesChatMessage>) => {
            updateConversation(state, action.payload);
        },
        updateConversationMessage: (state: ImagesState, action: PayloadAction<IImagesChatMessage>) => {
            overwriteLastMessage(state, action.payload);
        },
        updateImagesBotResponseStatus: (state: ImagesState, action: PayloadAction<string>) => {
            state.conversation.botResponseStatus = action.payload;
        },
        updateMessageSuccess: (state: ImagesState, action: PayloadAction<boolean>) => {
            state.conversation.isMessageSuccess = action.payload;
        },
        setGallery: (state: ImagesState, action: PayloadAction<IGalleryResponse | undefined>) => {
            if (action.payload === undefined) {
                return;
            }
            const { items, page, size, pages, total } = action.payload;
            state.gallery.items = [...items];
            state.gallery.page = page;
            state.gallery.size = size;
            state.gallery.pages = pages;
            state.gallery.total = total;
        },
        updateFavorite(state: ImagesState, action: PayloadAction<{ index: number; favorite: boolean }>) {
            const { index, favorite } = action.payload;
            if (state.gallery.items[index]) {
                state.gallery.items[index].favorite = favorite;
            }
        },
        updateFeedback(
            state: ImagesState,
            action: PayloadAction<{ index: number; feedback: 'positive' | 'negative' | null }>,
        ) {
            const { index, feedback } = action.payload;
            if (state.gallery.items[index]) {
                state.gallery.items[index].feedback = feedback;
            }
        },
    },
});

const updateConversation = (state: ImagesState, message: IImagesChatMessage) => {
    state.conversation.messages.push({ ...message });
};

const overwriteLastMessage = (state: ImagesState, message: IImagesChatMessage) => {
    const messagesLength = state.conversation.messages.length;
    if (messagesLength > 0) {
        state.conversation.messages[messagesLength - 1] = { ...message };
    }
};

export const {
    setConversationMessage,
    updateConversationMessage,
    updateImagesBotResponseStatus,
    updateMessageSuccess,
    setGallery,
    updateFavorite,
    updateFeedback,
} = imagesSlice.actions;

export default imagesSlice.reducer;
