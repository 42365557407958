import { useDispatch, useSelector } from 'react-redux';
import { nextPopover, openPopover, skipPopovers } from '../redux/features/popoverSlice';
import PopoverWithArrow from './PopoverWithArrow';
import { RootState, AppDispatch } from '../redux/app/store';
import React, { useEffect, useState } from 'react';
import Rewrite from '../assets/images/Rewrite.png';

const FirstPopover: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const { popovers, lastPopoverSeen } = useSelector((state: RootState) => state.popover);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const rootClassName = popovers[0] ? 'wizard-shadow' : '';

    useEffect(() => {
        const lastSeen = localStorage.getItem('lastPopoverSeen');

        const isDesktop = window.innerWidth >= 1024;

        if (lastSeen !== 'true' && isDesktop) {
            dispatch(openPopover(0));
        }
    }, [dispatch]);

    useEffect(() => {
        if (popovers[0]) {
            setAnchorEl(document.querySelector('#first-anchor') as HTMLElement);
        }
    }, [popovers[0]]);

    const handleNext = () => {
        dispatch(nextPopover({ current: 0 }));
    };

    const handleSkip = () => {
        dispatch(skipPopovers());
    };

    if (lastPopoverSeen) {
        return null;
    }

    return (
        <div id="shadow" className={rootClassName}>
            <PopoverWithArrow
                open={popovers[0]}
                anchorEl={anchorEl}
                transitionDuration={0}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: -10,
                    horizontal: 'left',
                }}
                slotProps={{
                    root: {
                        sx: {
                            zIndex: 10000,
                        },
                    },
                    paper: {
                        sx: {
                            overflow: 'visible',
                            borderRadius: '8px',
                            '&:before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                left: 15,
                                width: 10,
                                height: 10,
                                backgroundColor: 'inherit',
                                transform: 'translateY(-50%) rotate(45deg)',
                                boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                            },
                        },
                    },
                }}
            >
                <div
                    style={{
                        padding: '24px 32px',
                        fontSize: '18px',
                        minWidth: '200px',
                    }}
                >
                    <div
                        style={{
                            fontWeight: '700',
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span>New feature!</span>
                        <span>1/3</span>
                    </div>
                    <div>
                        Now, you can choose your preferred model.
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>
                                <strong>Gemini 1.5 Pro</strong> for complex tasks and large documents
                            </li>
                            <li>
                                <strong>GPT-4o</strong> if you want a more creative answer
                            </li>
                        </ul>
                        If an answer does not suit you, you can as well switch model
                        <br />
                        by clicking the <img
                            style={{ verticalAlign: 'text-bottom' }}
                            src={Rewrite}
                            alt="Rewrite"
                        />{' '}
                        button directly in the chat bubble.
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'end',
                            gap: '16px',
                            marginTop: '16px',
                        }}
                    >
                        <div
                            onClick={handleSkip}
                            style={{
                                textDecoration: 'underline',
                                textUnderlineOffset: '7px',
                                textTransform: 'uppercase',
                                fontWeight: '400',
                                fontSize: '12px',
                                cursor: 'pointer',
                            }}
                        >
                            Skip the onboarding tour
                        </div>
                        <div
                            onClick={handleNext}
                            style={{
                                borderRadius: '28px',
                                background: '#030F2B',
                                color: '#fff',
                                padding: '8px 32px',
                                textAlign: 'center',
                                fontWeight: '400',
                                fontSize: '12px',
                                cursor: 'pointer',
                                textTransform: 'uppercase',
                            }}
                        >
                            Next
                        </div>
                    </div>
                </div>
            </PopoverWithArrow>
        </div>
    );
};

export default FirstPopover;
